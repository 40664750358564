<template>
  <v-container>
    <v-overlay v-model="gettingCardData" opacity="0.8">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>

    <!-- Upload Image Dialog -->
    <v-dialog persistent max-width="700" v-model="uploadImage">
      <v-card>
        <v-container>
          <v-card-title>Upload Card Images</v-card-title>
          <vue-upload-multiple-image
            class="insi"
            @upload-success="uploadImageSuccess"
            dropText="Remove"
            markIsPrimaryText="Others"
            primaryText="Main image"
            dragText="Drag your image and receipts here"
            browseText="Pick a image"
            popupText="Edit Image"
            @before-remove="beforeRemove"
            @edit-image="editImage"
            :data-images="images"
            idUpload="myIdUpload"
            editUpload="myIdEdit"
          ></vue-upload-multiple-image>
          <v-btn
            :loading="loadingCreate"
            color="primary"
            @click="createSell"
            large
            :disabled="images.length == 0"
            class="mt-5"
            >Upload</v-btn
          >
        </v-container>
      </v-card>
    </v-dialog>
    <!-- End  -->

    <!-- Card Type info Dialog -->
    <v-dialog persistent max-width="600" v-model="cardTypeExplainDialog">
      <v-card>
        <v-card-title class="primary--text"
          >What is
          {{
            selectedCardTypeData !== 'undefined'
              ? selectedCardTypeData.cardType
              : ''
          }}?</v-card-title
        >
        <v-card-text>
          <p class="error--text mb-4">
            Note: Can can only sell ${{ selectedCardTypeData.minimumSell }} and
            above for this card Type
          </p>
          <p>{{ selectedCardTypeData.description }}</p>
          <v-img
            contain
            :src="selectedCardTypeData.image"
            :alt="
              selectedCardTypeData !== 'undefined'
                ? selectedCardTypeData.cardType
                : ''
            "
          ></v-img>
        </v-card-text>
        <v-card-actions>
          <v-btn
            width="65"
            color="primary"
            @click="cardTypeExplainDialog = false"
            >Okay</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- End -->

    <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
      <v-icon left> mdi-arrow-left-thick </v-icon>
      Back
    </v-btn>
    <h1 class="text-lg">Sell {{ cardData.name }} Giftcard</h1>
    <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p>
    <v-row>
      <v-col cols="12" sm="5">
        <v-card
          flat
          color="white"
          rounded="lg"
          class="pa-5 animated__animated animate__fadeIn"
        >
          <v-img width="100%" :src="cardData.image"></v-img>
        </v-card>
      </v-col>
      <v-col cols="12" sm="7">
        <v-card color="grey lighten-5" flat rounded="lg">
          <v-container>
            <v-card-title>Select Country</v-card-title>
            <v-autocomplete
              v-model="selectedOptionId"
              item-text="countryName"
              item-value="id"
              :items="availableCountry"
              @change="changeAvailabeCountry"
              filled
              label="Select a country..."
            >
              <template v-slot:item="{ item }">
                <v-list-item-avatar tile>
                  <v-img
                    width="40"
                    height="30"
                    contain
                    :src="objectImage[item.countryName.toLowerCase()]"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content
                  v-text="item.countryName"
                ></v-list-item-content>
              </template>
            </v-autocomplete>
            <v-divider class="my-2"></v-divider>
            <p>
              {{
                selectedOptionId !== ''
                  ? availableCountry.find((e) => e.id === selectedOptionId)
                      .currencyLabel
                  : ''
              }}
            </p>
            <div v-if="selectedOptionId !== ''">
              <p class="font-weight-medium">Select Card Type</p>
              <v-item-group
                @change="changeCardType"
                v-model="selectedCardTypeIndex"
                mandatory
              >
                <v-container>
                  <v-row no-gutters dense>
                    <v-col
                      v-for="card in availableCardTypes"
                      :key="card._id"
                      cols="4"
                      md="3"
                      class="h-100"
                    >
                      <v-item v-slot="{ active, toggle }">
                        <v-card
                          flat
                          ripple
                          outlined
                          rounded="lg"
                          :color="active ? 'accent' : ''"
                          class="align-center pa-2 h-100 h-auto mx-1"
                          @click="toggle"
                        >
                          <v-scroll-y-transition>
                            <div class="flex-grow-1 text-center">
                              <v-img
                                src="@/assets/img/card-type.7a408e45.svg"
                              ></v-img>
                              <p style="font-size: 12px" class="my-1">
                                {{ card !== undefined ? card.cardType : '' }}
                              </p>
                            </div>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
              <p class="text-center info--text font-weight-semibold">
                Any card above $100 should be uploaded as Large Card
              </p>
              <v-divider class="my-2"></v-divider>
              <v-card class="grey lighten-5 pa-1" flat>
                <v-row>
                  <v-col
                    v-for="(n, i) in sellCard.cart"
                    :key="n._id"
                    cols="12"
                    md="6"
                  >
                    <v-card
                      v-if="!n.type"
                      flat
                      rounded="lg"
                      outlined
                      class="px-2"
                    >
                      <v-row align="center" justify="space-around">
                        <v-col cols="3">
                          <v-img :src="cardData.image" width="100%"></v-img>
                        </v-col>
                        <v-col cols="5">
                          <p
                            style="font-size: 14px"
                            class="font-weight-medium mb-0 text-left"
                          >
                            {{
                              selectedOptionId !== ''
                                ? availableCountry.find(
                                    (e) => e.id === selectedOptionId
                                  ).currencySymbol
                                : ''
                            }}{{ n.value }} {{ cardData.name }}
                          </p>
                          <span
                            style="font-size: 12px"
                            class="text-left grey--text"
                            >{{
                              calculatedPriceBasedOnUser(n.nairaRate, n.value)
                            }}
                            {{ userCurrency }}</span
                          >
                        </v-col>
                        <v-col cols="4" class="d-flex px-1">
                          <v-icon @click="decreaseQuality(i)" class="mx-1"
                            >mdi-minus</v-icon
                          >
                          <span class="font-weight-bold">{{ n.quantity }}</span>
                          <v-icon @click="increaseQuality(i)" class="mx-1"
                            >mdi-plus</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <!-- <small class="d-block mx-auto text-center"
                    >Add a value to the field below then click on 'add other
                    value' to add more values</small
                  > -->
                </v-row>
                <p
                  v-if="
                    !sellCard.cart.filter((d) => d.type === 'added').length > 0
                  "
                  class="mt-3 subtitle-2"
                >
                  Value not listed?
                  <span
                    @click="addMoreOptions"
                    text
                    class="accent--text cursor-pointer"
                  >
                    <v-icon color="accent">mdi-plus</v-icon> Add other value
                  </span>
                </p>
                <div class="mt-3">
                  <div v-for="(n, index) in sellCard.cart" :key="index">
                    <v-row v-if="n.type === 'added'">
                      <v-col cols="8">
                        <small
                          class="error--text"
                          v-if="
                            sellCard.cart[index].variationAmount !== 0 &&
                            sellCard.cart[index].variationAmount <
                              selectedCardTypeData.minimumSell
                          "
                        >
                          The minimum card value you can trade under this
                          channel is ${{ selectedCardTypeData.minimumSell }}
                        </small>
                        <div>
                          <p style="fonts-size: 14px">Other Card Value</p>
                          <div
                            class="d-flex justify-center align-center white"
                            style="height: 60px"
                          >
                            <input
                              style="width: 70%"
                              v-model="sellCard.cart[index].variationAmount"
                              @keyup="getNiceRate(index)"
                              placeholder="Input value"
                              class="pl-2 other-input"
                              type="text"
                            />
                            <div>
                              <div>
                                <v-icon
                                  @click="
                                    () => {
                                      sellCard.cart[index].quantity === 0
                                        ? 0
                                        : sellCard.cart[index].quantity-- || 0
                                    }
                                  "
                                  >mdi-minus</v-icon
                                >
                                <span class="mx-2">{{
                                  sellCard.cart[index].quantity
                                }}</span>
                                <v-icon
                                  @click="
                                    () => {
                                      sellCard.cart[index].quantity++ || 0
                                    }
                                  "
                                  >mdi-plus</v-icon
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div>
                          <p style="fonts-size: 14px">Estimated Value</p>
                          <div
                            class="grey lighten-4 d-flex align-center"
                            style="height: 60px"
                          >
                            <p style="font-size: 13px" class="pl-3 mb-0">
                              {{ userCurrency }}
                              {{
                                estimatedValueBaseOnUser(
                                  sellCard.cart[index].nairaRate,
                                  sellCard.cart[index].variationAmount,
                                  sellCard.cart[index].quantity
                                )
                              }}
                              @ {{ sellCard.cart[index].nairaRate }}/$
                            </p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    v-if="
                      sellCard.cart.filter((d) => d.type === 'added').length > 0
                    "
                    class="control d-flex align-end flex-column"
                  >
                    <p
                      @click="addMoreOptions"
                      style="font-size: 14px"
                      class="accent--text mb-0 font-weight-bold cursor-pointer"
                    >
                      <v-icon color="accent" small>mdi-plus</v-icon> Add more
                    </p>
                    <p
                      @click="
                        () => {
                          sellCard.cart.pop()
                        }
                      "
                      style="font-size: 14px"
                      class="accent--text mb-0 font-weight-bold cursor-pointer"
                    >
                      Undo
                    </p>
                  </div>
                </div>
                <div class="d-none d-sm-block mt-3">
                  <v-text-field
                    v-model="promoCode"
                    label="Promo Code"
                    filled
                    prepend-icon="mdi-help"
                    @click:prepend="aboutPromoCode"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        color="primary"
                        @click="applyPromoCode"
                        :loading="loadingPromoCode"
                        :disabled="!promoCode"
                      >
                        Apply
                      </v-btn>
                    </template>
                  </v-text-field>
                </div>
                <div class="d-block d-sm-none mt-3">
                  <v-text-field
                    v-model="promoCode"
                    label="Promo Code"
                    filled
                    prepend-icon="mdi-help"
                    @click:prepend="aboutPromoCode"
                  >
                  </v-text-field>
                  <v-btn
                    color="primary"
                    @click="applyPromoCode"
                    :loading="loadingPromoCode"
                    :disabled="!promoCode"
                    block
                  >
                    Apply
                  </v-btn>
                </div>
                <p class="text-center mb-1 overline subtitle-2">YOU GET</p>
                <h4 class="text-h5 mb-3 text-center">
                  {{ userCurrency }}
                  {{ numberWithCommas(calculatedPrice) }}
                </h4>
                <p
                  v-if="promoCodeBonus"
                  class="text-center mb-1 overline subtitle-2"
                >
                  <small
                    >Promo Code Bonus {{ numberWithCommas(promoCodeBonus) }}
                  </small>
                  <br />
                  <small
                    >Total {{ numberWithCommas(calculatedPriceWithBonus) }}
                  </small>
                </p>

                <v-btn
                  :loading="initingSell"
                  @click="uploadImage = true"
                  class="accent"
                  block
                  :disabled="calculatedPrice <= 0 || loadingPromoCode"
                >
                  Submit
                </v-btn>
                <p class="text-center info--text font-weight-semibold my-2">
                  Average Confirmation time
                  <b>{{ giftcardResponseTime || '5-40' }} Minutes</b>
                </p>
              </v-card>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dataApi from '../../../../api/systemData'
import giftcardApi from '../../../../api/users/giftcard'
import transactionApi from '../../../../api/users/transactions'
import { numberWithCommas } from '../../../../utils/helpers'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import countryFlags from '../../../../utils/countryFlags'
// import { COUNTRIES } from '../../../../utils/countryHelpers'
import { mapState } from 'vuex'
import promoCodes from '../../../../api/promo-codes'

export default {
  components: {
    VueUploadMultipleImage
  },
  data: () => ({
    numberWithCommas,
    selectedOptionId: '',
    selectedCardTypeIndex: -1,

    cardTypeExplainDialog: false,
    selectedCardType: {},
    cardId: '',
    giftcardResponseTime: '',
    uploadImage: false,
    cardData: '',
    options: {},
    gettingCardData: false,

    createdSellData: {},
    images: [],
    imageFiles: [],
    valueForNewCard: { option: '', quantity: '' },
    nairaRate: '',
    sellCard: {
      cart: []
    },
    valueCardType: null,
    value: null,
    loadingCreate: false,
    initingSell: false,
    otherValue: [],
    countriesWithData: [],
    objectImage: {},

    promoCode: '',
    loadingPromoCode: false,
    promoCodeBonus: 0
  }),
  async created() {
    this.cardId = this.$route.params.id
    this.getCardData()
    this.$store.dispatch('user/getCountryCurrency')
    this.getTime()
    this.objectImage = countryFlags
  },
  watch: {
    calculatedPrice() {
      this.promoCodeBonus = 0
      if (this.promoCode !== '') {
        this.applyPromoCode()
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      siteData: (state) => state.user.siteSettings,
      countrySetting: (state) => state.user.countryCurrency
    }),
    calculatedPrice() {
      if (this.sellCard.cart.length === 0) return numberWithCommas(0)
      const price = this.sellCard.cart.reduce(
        (a, b) => a + b.variationAmount * b.quantity * b.nairaRate,
        0
      )

      return Number(
        price / this.countrySetting?.[this.userCountry.toLowerCase()]?.nairaRate
      ).toFixed(2)
    },
    calculatedPriceBase() {
      if (this.sellCard.cart.length === 0) return numberWithCommas(0)
      const price = this.sellCard.cart.reduce(
        (a, b) => a + b.variationAmount * b.quantity * b.nairaRate,
        0
      )

      return Number(price).toFixed(2)
    },
    calculatedPriceWithBonus() {
      const calc = Number(this.calculatedPrice) + Number(this.promoCodeBonus)
      return numberWithCommas(calc)
    },
    selectedCardTypeOptions() {
      if (Object.keys(this.selectedCardTypeData).length > 0) {
        return this.selectedCardTypeData.options
      }
      return []
    },
    selectedCardTypeData() {
      if (this.selectedCardTypeIndex >= 0) {
        return this.availableCardTypes[this.selectedCardTypeIndex || 0] !==
          undefined
          ? this.availableCardTypes[this.selectedCardTypeIndex || 0]
          : {}
      }
      return {}
    },
    availableCardTypes() {
      if (this.selectedOptionId) {
        return this.cardData.options.find(
          (op) => this.selectedOptionId === op._id
        ).cardTypes
      }
      return []
    },
    availableCountry() {
      if (this.cardData) {
        return this.cardData.options.map((op) => ({
          id: op._id,
          countryName: op.country.name,
          currencySymbol: op?.currency?.symbol,
          currencyLabel: op?.currency?.cc
        }))
      }
      return []
    }
  },
  methods: {
    calculatedPriceBasedOnUser(nairaRate, value) {
      return numberWithCommas(
        Number(
          (Number(nairaRate) * Number(value)) /
            this.countrySetting?.[this.userCountry.toLowerCase()].nairaRate
        ).toFixed(2)
      )
    },
    estimatedValueBaseOnUser(nairaRate, variationAmount, quantity) {
      const calc = Number(nairaRate * variationAmount * quantity).toFixed(2)

      return numberWithCommas(
        Number(
          calc / this.countrySetting?.[this.userCountry.toLowerCase()].nairaRate
        ).toFixed()
      )
    },
    changeAvailabeCountry() {
      this.sellCard.cart = []
      setTimeout(() => {
        this.selectedCardTypeIndex = 0
        this.changeCardType()
      }, 300)
    },
    increaseQuality(i) {
      this.sellCard.cart[i].quantity++
    },
    decreaseQuality(i) {
      this.sellCard.cart[i].quantity--
    },
    addMoreOptions() {
      this.sellCard.cart.push({
        type: 'added',
        nairaRate: 0,
        variationAmount: 0,
        quantity: 0,
        cardType: this.selectedCardTypeData
      })
    },
    getNiceRate(index) {
      const optionCard = this.sellCard.cart[index]

      if (optionCard.variationAmount < this.selectedCardTypeData.minimumSell) {
      }

      const optionMapToASpecificValue = this.selectedCardTypeOptions.find(
        (option) => option.value === optionCard.option
      )?.nairaRate

      const optionAvailable = this.selectedCardTypeOptions.find(
        (option) =>
          optionCard.variationAmount >= option.minimum &&
          optionCard.variationAmount <= option.maximum
      )

      this.sellCard.cart[index].nairaRate =
        optionMapToASpecificValue ||
        (optionAvailable !== undefined
          ? optionAvailable.nairaRate
          : this.selectedCardTypeOptions.find(
              (option) => option.default === true
            ).nairaRate ||
            this.selectedCardTypeOptions.find(
              (option) => option.default === true
            ).value)
    },
    changeCardType(data) {
      this.sellCard.cart = []

      this.sellCard.cart = this.selectedCardTypeOptions
        .filter(
          (option) => option.isVariable === false && option.default === false
        )
        .map((optionCard) => {
          const optionAvailable = this.selectedCardTypeOptions.find(
            (option) =>
              optionCard.value >= option.minimum &&
              optionCard.value <= option.maximum
          )

          if (optionAvailable !== undefined) {
            return {
              ...optionCard,
              nairaRate: optionAvailable.nairaRate,
              variationAmount: optionCard.value,
              quantity: 0
            }
          }
          return {
            ...optionCard,
            nairaRate:
              optionCard.nairaRate ??
              this.selectedCardTypeOptions.find(
                (option) => option.default === true
              ).nairaRate ??
              this.selectedCardTypeOptions.find(
                (option) => option.default === true
              ).value,
            variationAmount: optionCard.value,
            quantity: 0
          }
        })

      this.cardTypeExplainDialog = true
    },

    async initSell() {
      return new Promise((resolve, reject) => {
        this.initingSell = true

        const sellCardPayload = this.sellCard.cart.filter(
          (options) => options.quantity > 0
        )

        giftcardApi
          .giftcard()
          .sell({
            cardId: this.cardId,
            data: {
              promoCode: this.promoCode,
              cart: sellCardPayload,
              cardOptionId: this.selectedOptionId,
              cardTypeId: this.selectedCardTypeData._id
            }
          })
          .then((res) => {
            if (res.error) {
              this.$store.dispatch('alert', {
                message: res.errorMessage.message,
                error: true,
                status: true
              })
              this.loadingCreate = false
              reject(res.errorMessage)
              return
            }
            this.createdSellData = res.data.data
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })

      // this.uploadImage = true
    },

    uploadImageSuccess(formData, index, fileList) {
      // console.log('data', formData, index, fileList)
      this.imageFiles.push(formData.getAll('file')[0])
      this.images = fileList

      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done, fileList) {
      // console.log('index', index, fileList)
      var r = confirm('remove image')
      if (r === true) {
        // console.log(fileList)
        // console.log(fileList[index].name)
        var imgIndex = this.imageFiles.indexOf(fileList[index].name)
        this.imageFiles.splice(imgIndex)
        done()
      } else {
      }
    },
    editImage(formData, index, fileList) {
      // console.log('edit data', formData, index, fileList)
    },

    async createSell() {
      this.loadingCreate = true
      await this.initSell()
      // console.log(this.images)
      const res = await transactionApi.transactions().addImageToTransaction({
        images: this.imageFiles,
        trxId: this.createdSellData._id
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingCreate = false
        return
      }
      this.uploadImage = false
      this.loadingCreate = false
      this.images = []
      this.initingSell = false
      this.sellCard = {
        countryId: '',
        cart: [{ quantity: '', cardType: '', option: '' }]
      }
      this.$store.dispatch('alert', {
        message: 'Transaction sent! Kindly wait for approval',
        status: true
      })
      this.$router.push('/user/dashboard')
    },

    async getTime() {
      const res = await dataApi.data().siteSettings('user')
      this.giftcardResponseTime = res.data.data.giftcardResponseTime
    },
    async getCardData() {
      this.gettingCardData = true
      const res = await dataApi.data().getSingleData('user', {
        type: 'giftcard',
        params: {
          populate: ['options.country', 'options.currency']
        },
        typeId: this.cardId
      })
      this.cardData = res.data.data
      this.gettingCardData = false
    },
    async applyPromoCode() {
      this.loadingPromoCode = true
      const res = await promoCodes.promoCodes().calculateCodeBonus({
        promoCode: this.promoCode.trim(),
        productType: 'giftcard',
        amount: Number(this.calculatedPriceBase.toString().replace(/,/g, '')),
        productId: this.cardId
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message: res.errorMessage.message,
          error: true,
          status: true
        })
        this.$swal.fire({
          icon: 'error',
          title: 'Unable to apply code',
          text: res.errorMessage.message
        })
        this.loadingPromoCode = false
        return
      }

      if (res.data?.data?.discountValue) {
        this.promoCodeBonus = res.data.data.discountValue

        this.promoCodeBonus = Number(this.promoCodeBonus).toFixed(2)
      } else {
        this.promoCodeBonus = 0
        this.$swal.fire({
          icon: 'error',
          title: 'Unable to apply code',
          text: res.data.data
        })
      }

      this.loadingPromoCode = false
    },
    async aboutPromoCode() {
      this.$swal.fire({
        icon: 'info',
        title: 'About Promo Codes',
        text:
          'Do you have a promo code? Enter it here to see if you qualify for a trade bonus. If you do not have any, leave it empty. To see available promo codes, check the Promo codes page on our Blog  or follow Snappy Exchange on Instagram @SnappyExchangeNg'
      })
    }
  }
}
</script>

<style>
.insi .image-container {
  width: 100% !important;
}

.other-input {
  height: 45px;
  font-size: 14px;
}

.other-input:focus {
  outline: none;
}
</style>
